import React from "react";

import {Page} from "./page";

// Stores
import {ApiStore} from "../stores/api-store";

// Components
import {PageNotFound} from "../components/interstitials";
import {PageTitles, withRideOctaneTitle} from "./octane-title";


// 404 Not Found Page
export function notFound() {

    const store = Page.createStore({
        isPage404: true,
        stores: {
            apiStore: new ApiStore(),
        },
    });

    const titleWords = [PageTitles.PAGE_NOT_FOUND_TITLE];
    const component = Page.createComponent({
        content: withRideOctaneTitle(<PageNotFound/>, titleWords),
    });

    return {store, component};
}
